<template lang="pug">
#container(v-show='color')
ul(v-show='!color')
  li
    router-link(to='/model-show?color=炫彩') 炫彩
  li
    router-link(to='/model-show?color=黑色') 黑色
  li
    router-link(to='/model-show?color=蓝色') 蓝色
</template>

<script>
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { onMounted, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: 'ModelShow',
  setup() {
    let color = ref(null);
    const route = useRoute();
    color.value = route.query?.color;
    watchEffect(() => {
      if (color.value !== route.query?.color) {
        window.requestAnimationFrame(() => window.location.reload());
      }
    });

    onMounted(() => {
      if (!color.value) {
        return;
      }

      init(color.value);

      function init(color = '炫彩') {
        const container = document.getElementById('container');

        const camera = new THREE.PerspectiveCamera(
          45,
          window.innerWidth / window.innerHeight,
          0.25,
          20,
        );
        camera.position.set(-1.8, 0.6, 2.7);

        const scene = new THREE.Scene();

        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.toneMapping = THREE.ACESFilmicToneMapping;
        renderer.toneMappingExposure = 1;
        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.setClearColor(0xffffff, 1);

        // renderer.shadowMapEnabled = true;
        // render.shadowMap = 0;

        // renderer.shadowMapEnabled = true;
        // render.shadowMap = 0;

        container.appendChild(renderer.domElement);

        const orbit = new OrbitControls(camera, renderer.domElement);

        // 光源设置
        // const ambient = new THREE.AmbientLight(0xffffff, 0);
        // scene.add(ambient);

        // const directionalLight1 = new THREE.DirectionalLight(0xffffff, 0);
        // directionalLight1.position.set(0, 0, -400).normalize();
        // scene.add(directionalLight1);

        // const pointLight2 = new THREE.PointLight(0xffff00, 10);
        // pointLight2.position.set(-10000, 10000, -10).normalize();
        // scene.add(pointLight2);

        // const pointLight1 = new THREE.PointLight(0xff0000, 10000);
        // pointLight1.position.set(10000, -10000, -10).normalize();
        // scene.add(pointLight1);

        // const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1);
        // directionalLight2.position.set(-100, -100, 400).normalize();
        // scene.add(directionalLight2);
        // const directionalLight2 = new THREE.DirectionalLight(0xffffff, 2);
        // directionalLight2.position.set(0, 200, 200).normalize();
        // scene.add(directionalLight2);

        // const directionalLight3 = new THREE.DirectionalLight(0xffffff, 2);
        // directionalLight2.position.set(-100, -200, 100).normalize();
        // scene.add(directionalLight3);

        // const light = new THREE.HemisphereLight(0xffffff, 0x000000, 100);
        // scene.add(light);

        const pmremGenerator = new THREE.PMREMGenerator(renderer);
        pmremGenerator.compileEquirectangularShader();
        new RGBELoader()
          .setDataType(THREE.UnsignedByteType)
          .setPath('assets/models/')
          .load('envbox.hdr', (texture) => {
            const envMap = pmremGenerator.fromEquirectangular(texture).texture;
            scene.environment = envMap;
            // scene.background = envMap;
            // setTimeo2t(() => {
            loadGtlf(envMap);
            // }, 5000);
          });

        const metalTexture = new THREE.TextureLoader().load(
          'assets/models/material/metal_texture.jpg',
        );
        const displayTexture = new THREE.TextureLoader().load(
          'assets/models/material/displacement_texture.jpg',
        );
        const roughnessTexture = new THREE.TextureLoader().load(
          'assets/models/material/roughness_texture.jpg',
        );
        const metalnessTexture = new THREE.TextureLoader().load(
          'assets/models/material/metalness_texture.jpg',
        );
        const normalTexture = new THREE.TextureLoader().load(
          'assets/models/material/normal_texture.jpg',
        );

        const pebbleTexture = new THREE.TextureLoader().load('assets/models/material/pebble.jpg');

        const screenTexture = new THREE.TextureLoader().load('assets/models/material/screen.jpg');

        const camera1_glass_texture = new THREE.TextureLoader().load(
          'assets/models/material/camera1_glass.png',
        );

        const camera2_glass_texture = new THREE.TextureLoader().load(
          'assets/models/material/camera2_glass.png',
        );

        const camera3_glass_texture = new THREE.TextureLoader().load(
          'assets/models/material/camera3_glass.png',
        );

        const camera4_glass_texture = new THREE.TextureLoader().load(
          'assets/models/material/camera4_glass.png',
        );

        const loadGtlf = () => {
          const loader = new GLTFLoader().setPath('assets/models/');
          loader.load('20210114.gltf', function(gltf) {
            const object = gltf.scene;
            // console.log(object);
            object.children[0].children.forEach((child) => {
              if (child.children.length > 0) {
                child.children.forEach((sub) => {
                  material(sub);
                });
              } else {
                material(child);
              }
            });

            const box = new THREE.Box3().setFromObject(object);
            const size = box.getSize(new THREE.Vector3()).length();
            // console.log(size);
            const center = box.getCenter(new THREE.Vector3());

            orbit.enableZoom = false;
            orbit.addEventListener('change', render);
            // orbit.minDistance = size * 1.5;
            // orbit.maxDistance = size * 3;
            orbit.target.set(0, 0, 0);
            orbit.update();

            camera.near = size / 100;
            camera.far = size * 100;
            camera.updateProjectionMatrix();

            camera.position.copy(center);
            camera.position.x += size / 2;
            camera.position.y += size / 2;
            camera.position.z += size * 1.5;
            camera.lookAt(center);

            scene.add(object);

            render();
          });
        };

        window.addEventListener('resize', onWindowResize, false);

        function onWindowResize() {
          camera.aspect = window.innerWidth / window.innerHeight;
          camera.updateProjectionMatrix();

          renderer.setSize(window.innerWidth, window.innerHeight);

          render();
        }

        function render() {
          renderer.render(scene, camera);
        }

        const confs = {
          炫彩: {
            back: {
              material: {
                bumpMap: pebbleTexture,
                bumpScale: 0.015,
                // normalMap: pebbleTexture,
                // normalScale: 0.03,
                color: new THREE.Color(0xeeeeee),
                colorWrite: true,
                roughness: 0.1,
                metalness: 0.98,
                envMapIntensity: 2.2,
              },
              texture: 'back_color.hdr',
            },
            frame: {
              material: {
                map: metalTexture,
                displacementMap: displayTexture,
                metalnessMap: metalnessTexture,
                roughnessMap: roughnessTexture,
                bumpMap: normalTexture,
                side: THREE.FrontSide,
                colorWrite: true,
                opacity: 1,
                envMapIntensity: 3,
                metalness: 1,
              },
              texture: 'envbox.hdr',
            },
            frameTop: {
              material: {
                color: new THREE.Color(0x1089f3),
                colorWrite: true,
                roughness: 0,
                metalness: 0.3,
                envMapIntensity: 3,
              },
              texture: 'back_color.hdr',
            },
            backText: {
              material: {
                map: null,
                color: new THREE.Color(0xeeeeee),
                metalness: 0.9,
                roughness: 0,
                colorWrite: true,
                envMapIntensity: 2.8,
              },
              texture: 'back_color_text.hdr',
            },
            backTextBlack: {
              material: {
                transparent: true,
                opacity: 0,
              },
            },
            topLetter: {
              material: {
                map: null,
                color: new THREE.Color(0x555555),
                metalness: 0.9,
                roughness: 0,
                colorWrite: true,
                envMapIntensity: 1,
              },
            },
            cameraGlass: {
              material: {
                color: new THREE.Color(0x1089f3),
                metalness: 0.1,
                roughness: 0,
                transparent: true,
                opacity: 0.85,
                envMapIntensity: 2,
                side: THREE.FrontSide,
              },
            },
            cameraBottom: {
              material: {
                map: null,
                color: new THREE.Color(0xffffff),
                metalness: 0.5,
                roughness: 0,
                // emissive: new THREE.Color(0xffffff),
                // emissiveIntensity: 1,
                envMapIntensity: 2,
              },
            },
            cameraText: {
              material: {
                map: null,
                color: new THREE.Color(0xcccccc),
                metalness: 0.6,
                roughness: 0,
                colorWrite: true,
                envMapIntensity: 1,
              },
              // texture: 'envbox.hdr',
            },
          },
          黑色: {
            back: {
              material: {
                bumpMap: pebbleTexture,
                bumpScale: 0,
                color: new THREE.Color(0x000),
                colorWrite: true,
                roughness: 0,
                metalness: 0,
                envMapIntensity: 1.5,
              },
              texture: 'envbox.hdr',
            },
            frame: {
              material: {
                color: new THREE.Color(0x000),
                displacementMap: displayTexture,
                metalnessMap: metalnessTexture,
                roughnessMap: roughnessTexture,
                bumpMap: normalTexture,
                side: THREE.FrontSide,
                colorWrite: true,
                opacity: 1,
                envMapIntensity: 12,
                metalness: 1,
              },
              texture: 'envbox.hdr',
            },
            frameTop: {
              material: {
                color: new THREE.Color(0x000),
                colorWrite: true,
                roughness: 0,
                metalness: 0,
                envMapIntensity: 1.5,
              },
            },
            backText: {
              material: {
                transparent: true,
                opacity: 0,
              },
              texture: 'back_color_text.hdr',
            },
            backTextBlack: {
              material: {
                map: null,
                color: new THREE.Color(0xeeeeee),
                metalness: 0.9,
                roughness: 0,
                colorWrite: true,
                envMapIntensity: 2.8,
              },
            },
            topLetter: {
              material: {
                color: new THREE.Color(0xffffff),
                metalness: 0.9,
                roughness: 0,
                colorWrite: true,
                envMapIntensity: 10,
                side: THREE.DoubleSide,
              },
            },
            cameraGlass: {
              material: {
                color: new THREE.Color(0x050609),
                metalness: 0,
                roughness: 0,
                transparent: true,
                opacity: 0.85,
                envMapIntensity: 2,
              },
            },
            cameraBottom: {
              material: {
                map: null,
                color: new THREE.Color(0x000000),
                metalness: 0,
                roughness: 0,
                // emissive: new THREE.Color(0xffffff),
                // emissiveIntensity: 1,
                envMapIntensity: 2,
              },
            },
            cameraText: {
              material: {
                map: null,
                color: new THREE.Color(0xffffff),
                metalness: 0.8,
                roughness: 0,
                colorWrite: true,
                envMapIntensity: 4,
              },
            },
          },
          蓝色: {
            back: {
              material: {
                bumpMap: pebbleTexture,
                bumpScale: 0.015,
                // normalMap: pebbleTexture,
                // normalScale: 0.025,
                color: new THREE.Color(0xeeeeee),
                colorWrite: true,
                roughness: 0.1,
                metalness: 0.98,
                envMapIntensity: 1.2,
                // side: THREE.BackSide,
              },
              texture: 'back_blue.hdr',
            },
            frame: {
              material: {
                color: new THREE.Color(0x1a75d6),
                displacementMap: displayTexture,
                metalnessMap: metalnessTexture,
                roughnessMap: roughnessTexture,
                bumpMap: normalTexture,
                side: THREE.FrontSide,
                colorWrite: true,
                opacity: 1,
                envMapIntensity: 3,
                metalness: 1,
              },
              texture: 'envbox.hdr',
            },
            frameTop: {
              material: {
                color: new THREE.Color(0x125296),
                colorWrite: true,
                roughness: 0,
                metalness: 0.3,
                envMapIntensity: 2,
              },
              texture: 'envbox.hdr',
            },
            backText: {
              material: {
                map: null,
                color: new THREE.Color(0xffffff),
                metalness: 1,
                roughness: 0,
                colorWrite: true,
                envMapIntensity: 1.5,
              },
              texture: 'back_blue_text.hdr',
            },
            backTextBlack: {
              material: {
                transparent: true,
                opacity: 0,
              },
            },
            topLetter: {
              material: {
                // map: white,
                color: new THREE.Color(0xffffff),
                metalness: 0.9,
                roughness: 0,
                colorWrite: true,
                envMapIntensity: 10,
                side: THREE.DoubleSide,
              },
            },
            cameraGlass: {
              material: {
                color: new THREE.Color(0x124496),
                metalness: 0.1,
                roughness: 0,
                transparent: true,
                opacity: 0.85,
                envMapIntensity: 0.7,
                side: THREE.FrontSide,
              },
            },
            cameraBottom: {
              material: {
                map: null,
                color: new THREE.Color(0x124496),
                metalness: 0.5,
                roughness: 0,
                // emissive: new THREE.Color(0xffffff),
                // emissiveIntensity: 1,
                envMapIntensity: 0.8,
              },
            },
            cameraText: {
              material: {
                map: null,
                color: new THREE.Color(0xffffff),
                metalness: 0.8,
                roughness: 0,
                colorWrite: true,
                envMapIntensity: 4,
              },
            },
          },
        };

        const theConfs = confs[color];

        // 设置材质
        // 背壳 - back
        const back = new THREE.MeshStandardMaterial(theConfs.back.material);
        back.bumpMap.wrapS = back.bumpMap.wrapT = THREE.RepeatWrapping;
        back.bumpMap.repeat.set(3.5, 7.24);
        // back.normalMap.wrapS = back.normalMap.wrapT = THREE.RepeatWrapping;
        // back.normalMap.repeat.set(3.5, 7.24);
        new RGBELoader()
          .setDataType(THREE.UnsignedByteType)
          .setPath('assets/models/')
          .load(theConfs.back.texture, (texture) => {
            const envMap = pmremGenerator.fromEquirectangular(texture).texture;
            back.envMap = envMap;
          });

        // 顶部 - frame_top
        const frame_top = new THREE.MeshStandardMaterial(theConfs.frameTop.material);
        new RGBELoader()
          .setDataType(THREE.UnsignedByteType)
          .setPath('assets/models/')
          .load(theConfs.frameTop.texture, (texture) => {
            const envMap = pmremGenerator.fromEquirectangular(texture).texture;
            frame_top.envMap = envMap;
          });

        // 顶部文字
        const top_letter = new THREE.MeshStandardMaterial(theConfs.topLetter.material);

        // 丝印文字
        const letter = new THREE.MeshStandardMaterial(theConfs.backText.material);
        new RGBELoader()
          .setDataType(THREE.UnsignedByteType)
          .setPath('assets/models/')
          .load(theConfs.backText.texture, (texture) => {
            const envMap2 = pmremGenerator.fromEquirectangular(texture).texture;
            letter.envMap = envMap2;
          });

        // 丝印文字黑色
        const letter_black = new THREE.MeshStandardMaterial(theConfs.backTextBlack.material);
        // new RGBELoader()
        //   .setDataType(THREE.UnsignedByteType)
        //   .setPath('assets/models/')
        //   .load(theConfs.backTextBlack.texture, (texture) => {
        //     const envMap2 = pmremGenerator.fromEquirectangular(texture).texture;
        //     letter.envMap = envMap2;
        //   });

        // 镜头模组玻璃 - camera_union_glass
        const camera_union_glass = new THREE.MeshStandardMaterial(theConfs.cameraGlass.material);

        // 镜头模组底面 - camera_union_bottom
        const camera_union_bottom = new THREE.MeshStandardMaterial(theConfs.cameraBottom.material);

        // 镜头文字
        const camera_letter = new THREE.MeshStandardMaterial(theConfs.cameraText.material);

        // 闪光玻璃 - camera_glass
        const flash_glass = new THREE.MeshStandardMaterial({
          color: new THREE.Color(0x888888),
          roughness: 0.3,
          metalness: 0,
          envMapIntensity: 2,
        });

        // 镜头玻璃 - camera_glass
        const camera1_texture = new THREE.MeshStandardMaterial({
          map: camera1_glass_texture,
        });

        const camera2_texture = new THREE.MeshStandardMaterial({
          map: camera2_glass_texture,
        });

        const camera3_texture = new THREE.MeshStandardMaterial({
          map: camera3_glass_texture,
        });

        const camera4_texture = new THREE.MeshStandardMaterial({
          map: camera4_glass_texture,
        });

        camera1_texture.envMapIntensity = camera2_texture.envMapIntensity = camera3_texture.envMapIntensity = camera4_texture.envMapIntensity = 0.3;
        camera1_texture.metalness = camera2_texture.metalness = camera3_texture.metalness = camera4_texture.metalness = 1;

        // 镜头体 - camera_body
        const camera_body = new THREE.MeshStandardMaterial({
          color: new THREE.Color(0x000000),
          metalness: 0.6,
          roughness: 0,
          colorWrite: true,
        });

        // 镜头玻璃
        const camera_glass = new THREE.MeshStandardMaterial({
          color: new THREE.Color(0x000000),
          metalness: 0.2,
          roughness: 0,
          colorWrite: true,
          transparent: true,
          opacity: 0.5,
        });

        // 镜头黑面
        const camera_cover = new THREE.MeshStandardMaterial({
          color: new THREE.Color(0x000000),
          metalness: 0.6,
          roughness: 0,
          colorWrite: true,
        });

        // 屏幕显示区域
        const screen_display = new THREE.MeshStandardMaterial({
          map: screenTexture,
          metalness: 0,
          roughness: 0,
          envMapIntensity: 5,
          side: THREE.FrontSide,
        });

        // 屏幕表面
        const screen_surface = new THREE.MeshStandardMaterial({
          color: new THREE.Color(0x000000),
          // alphaMap: alphaMap,
          metalness: 0,
          roughness: 0,
          colorWrite: true,
          transparent: true,
          opacity: 0.05,
          envMapIntensity: 0.5,
        });

        // 屏幕边框
        const screen_frame = new THREE.MeshStandardMaterial({
          color: new THREE.Color(0x000000),
          metalness: 0.9,
          roughness: 0,
          colorWrite: true,
          envMapIntensity: 2,
        });

        // 中框
        const frame = new THREE.MeshStandardMaterial(theConfs.frame.material);

        const power_paint = new THREE.MeshStandardMaterial({
          color: new THREE.Color(0x00ff00),
          metalness: 0.9,
          roughness: 0,
          colorWrite: true,
          envMapIntensity: 2,
        });

        // 更新材质
        function material(child) {
          child.material.depthWrite = true;
          child.material.transparent = false;

          // 背面贴图
          if (child.material.name === 'back') {
            child.material = back;
          }

          // 镜头模组玻璃
          if (child.material.name === 'camera_union_glass') {
            child.material = camera_union_glass;
            // child.receiveShadow = true;
            // child.castShadow = true;
          }

          // 镜头底面
          if (child.material.name === 'camera_union_bottom') {
            child.material = camera_union_bottom;
            // child.receiveShadow = true;
            // child.castShadow = true;
          }

          // 镜头文字
          if (child.material.name === 'camera_letter') {
            child.material = camera_letter;
            child.position.z -= 0.72;
            //   var vnh = new VertexNormalsHelper( child.geometry, 5 );
            //   scene.add(vnh);
          }

          // 丝印文字
          if (child.material.name === 'letter') {
            child.material = letter;
          }

          // 丝印文字_黑色
          if (child.material.name === 'letter_black') {
            child.material = letter_black;
          }

          // 顶部
          if (child.material.name === 'frame_top') {
            child.material = frame_top;
          }

          // 顶部文字
          if (child.material.name === 'top_letter') {
            child.material = top_letter;
          }

          // 屏幕
          if (child.material.name === 'screen_display') {
            child.material = screen_display;
          }

          // 屏幕表面
          if (child.material.name === 'screen_surface') {
            child.material = screen_surface;
          }

          // 镜头体
          if (child.material.name === 'camera_body') {
            child.material = camera_body;
          }

          // 闪光灯
          if (child.material.name === 'flash_glass') {
            child.material = flash_glass;
          }

          // 镜头 1 面
          if (child.material.name === 'camera1_texture') {
            child.material = camera1_texture;
          }

          // 镜头 2 面
          if (child.material.name === 'camera2_texture') {
            child.material = camera2_texture;
          }

          // 镜头 3 面
          if (child.material.name === 'camera3_texture') {
            child.material = camera3_texture;
          }

          // 镜头 4 面
          if (child.material.name === 'camera4_texture') {
            child.material = camera4_texture;
          }

          // 镜头玻璃
          if (child.material.name === 'camera_glass') {
            child.material = camera_glass;
          }

          // 镜头黑面
          if (child.material.name === 'camera_cover') {
            child.material = camera_cover;
          }

          // 屏幕边
          if (child.material.name === 'screen_frame') {
            child.material = screen_frame;
          }

          // 中框
          if (child.material.name === 'frame') {
            child.material = frame;
          }

          // sim 卡
          if (child.material.name === 'sim') {
            child.material = frame;
          }

          // 电源按键
          if (child.material.name === 'button_power_paint') {
            child.position.x += 0.08;
            child.material = power_paint;
            // var vnh = new VertexNormalsHelper( child, 5 );
            // scene.add( vnh );
          }
        }

        render();
      }
    });
    return { color };
  },
};
</script>

<style lang="stylus" scoped>
#container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
}
</style>
